/* TrialSignUp.css */

.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* This ensures the container takes up the full viewport height */
    text-align: center;
    }

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  /* Style for the Google Login container */
  .GoogleLoginContainer {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    }

  /* Style for the Facebook Login container */
  .FacebookLoginContainer {
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 5px;
  }
